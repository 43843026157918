import React from "react"

import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Kurzy from "../../components/kurzy/kurzy"

import "../../components/layout/layout.scss"
import "../../components/submenutreneri/submenutreneri.scss"
import "../index.scss"
import "../tanecni-kurzy.scss"

import ImgTreneriHybsova from "../../images/treneri/libuse-hybsova.jpg"
import ImgTreneriPixa from "../../images/treneri/jiri-pixa.jpg"

const TanecniPripravkaProDetiSkupina1 = () => (

	<Layout>
		<Seo title="Taneční přípravka pro děti - skupina 1" description="Taneční přípravka pro děti - skupina 1" />
		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Taneční přípravka pro&nbsp;děti -&nbsp;skupina 1</h1>
			<p className="mb-4">Všeobecná pohybová příprava pro&nbsp;taneční sport. Výuka probíhá na&nbsp;profesionální úrovni.</p>

			<Row>
				<Col xl="9" className="pb-5">

					<Row className="stk-seminar mb-2 mt-2">
						<Col lg="12" xl="6">
							<div className="stk-seminare__item">
								<span className="stk-seminare__day">PO</span>
								<span className="stk-seminare__time">14:00<br /><span className="stk-seminare__dash">-</span><br />15:00</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Taneční přípravka pro děti</span>
									<span className="stk-seminare__subtitle">skupina 1</span>
								</span>
							</div>
						</Col>
						<Col lg="12" xl="6">
							<div className="stk-seminare__item">
								<span className="stk-seminare__day">ST</span>
								<span className="stk-seminare__time">14:00<br /><span className="stk-seminare__dash">-</span><br />15:00</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Taneční přípravka pro děti</span>
									<span className="stk-seminare__subtitle">skupina 2</span>
								</span>
							</div>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2 pb-2">
							Místo:
						</Col>
						<Col xl="5">
							<p>
								<h3 className="stk-h3">Taneční sál - budova CASABLANCA</h3>
								Vinohradská 184, Praha-Vinohrady, 130&nbsp;00<br />
								<a className="stk-button stk-button--small mt-2" href="https://goo.gl/maps/gviNHcdBLMo6dmwg7" target="_blank" rel="noreferrer">zobrazit na mapě</a>
							</p>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Co na sebe:
						</Col>
						<Col xl="10">
							chlapci volnější kalhoty (tepláky), triko nebo košili<br />
							dívky kalhoty nebo sukni (tepláky), triko nebo košili
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Obuv:
						</Col>
						<Col xl="10">
							čisté tenisky nebo piškoty
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Co se naučíte:
						</Col>
						<Col xl="10">
							Všeobecná pohybová příprava pro&nbsp;taneční sport.<br />
							Výuka probíhá na&nbsp;profesionální úrovni.
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Cena:
						</Col>
						<Col xl="10">
							<span className="stk-h2 stk-h2--yellow">400,-</span> / měsíc<br />
							<span className="stk-h2 stk-h2--yellow">1700,-</span> / pololetí
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Přihláška:
						</Col>
						<Col xl="10">
							<strong>Jiří Pixa</strong><br />
							<a href="tel:+420602387109">+420 602 387 109</a><br />
							<a href="mailto:jj.pixa@seznam.cz">jj.pixa@seznam.cz</a>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Ostatní:
						</Col>
						<Col xl="10">
							možnost volných tréninků na&nbsp;sálech STK&nbsp;Praha dle&nbsp;rozvrhu<br />
							možnost individuálních lekcí s&nbsp;<Link to="../../treneri">trenéry STK Praha</Link>
						</Col>
					</Row>

				</Col>

				<Col xl="3" className="pb-5">
					<h2 className="stk-h2 mb-3">Trenérský tým</h2>
					<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/libuse-hybsova">
						<img className="stk-submenutreneri__image" alt="Libuše Hybšová" src={ImgTreneriHybsova} />
						Libuše Hybšová
					</Link>
					<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/jiri-pixa">
						<img className="stk-submenutreneri__image" alt="Jiří Pixa" src={ImgTreneriPixa} />
						Jiří Pixa
					</Link>
				</Col>

			</Row>

			<Kurzy></Kurzy>

		</div>
	</Layout>

)

export default TanecniPripravkaProDetiSkupina1